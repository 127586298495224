import { Injectable, inject } from '@angular/core';

import { Shipment } from '../models/shipment';

import { ShipmentItem } from '../models/shipment-item';

import { ShipmentCheckin } from '../models/shipment-checkin';

import { InternalFile } from '../models/internal-file';

import { ShipmentCheckout } from '../models/shipment-checkout';

import { AppConfig } from './app.config';

/**
 * Urls used within the application.
 * Stringified for convenience, since most of the Angular's HTTP tools work with strings.
 */
@Injectable({ providedIn: 'root' })
export class AppUrlsConfig {
	private readonly appConfigService = inject(AppConfig);

	/** Auth-related routes. */
	public readonly auth = {
		login: this.toApi('login/'),
		logout: this.toApi('logout/'),
		refreshSecret: this.toApi('auth/password-reset/'),
		resetPassword: this.toApi('auth/token/refresh/'),
		confirmPasswordReset: this.toApi('auth/password-reset-confirm/'),
	} as const;

	/** Routes for getting/editing current user's info. */
	public readonly user = {
		list: this.toApi('users/get-all'),
		currentProfile: this.toApi('profile/'),
		changePassword: this.toApi('users/change_password/'),
	} as const;

	/** Route related to shipments. */
	public readonly shipments = {
		checkBatchTransfer: this.toApi('shipments/batch-transfer/check-batch/'),
		transferBatch: this.toApi('shipments/batch-transfer/'),
		downloadBarcode: this.toApi('shipments/batch-transfer/download-pdf/'),
		locations: this.toApi('shipments/locations'),
		detail: (id: Shipment['id']) => this.toApi('shipments/trucks', id.toString()),
		detailItems: (id: Shipment['id']) => this.toApi('shipments', id.toString(), 'items'),
		items: () => this.toApi('shipments/items'),
		checkin: (id: Shipment['id'], checkinId: ShipmentCheckin['id']) =>
			this.toApi('shipments', id.toString(), 'checkin', checkinId.toString()),
		printItemBarcode: (id: Shipment['id'], itemId: ShipmentItem['id']) =>
			this.toApi('shipments', id.toString(), 'items', itemId.toString(), 'print-barcode'),
		notice: this.toApi('shipments/notices'),
		itemConditions: this.toApi('shipments/items-conditions'),
		checkIn: (shipmentId: number) => this.toApi('shipments', shipmentId.toString(), 'checkin'),
		getItemFiles: (shipmentId: Shipment['id'], itemId: ShipmentItem['id']) =>
			this.toApi('shipments', shipmentId.toString(), 'items', itemId.toString(), 'files'),
		createItemFiles: (shipmentId: Shipment['id'], itemId: ShipmentItem['id']) =>
			this.toApi('shipments', shipmentId.toString(), 'items', itemId.toString(), 'files'),
		deleteItemFile: (shipmentId: Shipment['id'], itemId: ShipmentItem['id'], fileId: InternalFile['id']) =>
			this.toApi('shipments', shipmentId.toString(), 'items', itemId.toString(), 'files', fileId.toString()),
		downloadItemFile: (fileId: InternalFile['id']) =>
			this.toApi('shipments', 'files', fileId.toString(), 'download'),
		viewFile: (fileId: InternalFile['id']) => this.toApi('client/shipments/files', fileId.toString(), 'view'),
		truck: this.toApi('shipments/trucks'),
		code: this.toApi('shipments/activity-codes'),
		checkout: this.toApi('shipments/checkout'),
		editCheckout: (id: ShipmentCheckout['id']) => this.toApi('shipments/checkout', id.toString()),
		createCheckoutFiles: (id: ShipmentCheckout['id']) => this.toApi('shipments/checkout', id.toString(), 'files'),
		deleteCheckoutFile: (id: ShipmentCheckout['id'], fileId: InternalFile['id']) =>
			this.toApi('shipments/checkout', id.toString(), 'files', fileId.toString()),
		checkoutItems: this.toApi('shipments/checkout/checkout-items'),
		truckCompany: this.toApi('shipments/truck-companies'),
	} as const;

	/** Routes for employee. */
	public readonly employee = {
		list: this.toApi('employees'),
	} as const;

	/** Routes for customer. */
	public readonly customer = {
		list: this.toApi('customers'),
	} as const;

	/** Routes for job. */
	public readonly job = {
		list: this.toApi('jobs/search'),
	} as const;

	/** Routes for location. */
	public readonly location = {
		list: this.toApi('locations'),
	} as const;

	/**
	 * Checks whether the url is application-scoped.
	 * @param url Url to check.
	 */
	public isApplicationUrl(url: string): boolean {
		return url.startsWith(this.appConfigService.apiUrl);
	}

	/**
	 * Checks whether the specified url is calling an auth-related endpoint.
	 * @param url Url to check.
	 */
	public isAuthUrl(url: string): boolean {
		return Object.values(this.auth).find(authUrl => authUrl.includes(url)) != null;
	}

	private toApi(...args: readonly string[]): string {
		const path = args.join('/');
		return new URL(path, this.appConfigService.apiUrl).toString();
	}
}
